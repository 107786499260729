<template>
  <v-container style="height: 100%;">
    <v-row :justify="loading ? 'center' : 'start'" :align="loading ? 'center' : 'start'" style="min-height: 100%;">
      <v-progress-circular
        v-if="loading"
        :size="40"
        :width="3"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-col
        v-else
        cols="12"
      >

        <h2 class="headline font-weight-bold mb-3">
          Elections
        </h2>

        <v-row v-if="elections.length">
          <v-col cols="12" sm="6" md="3" v-for="election in elections" :key="election.id">
            <v-card :to="`/election/${election.id}`">
              <v-card-title>{{ election.title }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <span v-else>There are no active elections.</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
      loading: false,
      elections: []
    }),

    created () {
      this.getElections()
    },

    methods: {
      async getElections () {
        this.loading = true
        const { success, data } = await this.$get('elections')
        if (success) {
          this.elections = data
        }
        this.loading = false
      },
    }
  }
</script>
